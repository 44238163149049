import $ from 'jquery'; // Import jQuery
import swal from 'sweetalert';


$(document).ready(function () {
  // Reservation Form Submission
  $('#reservation-form').on('submit', function (e) {
    e.preventDefault(); // Prevent form submission and page refresh
    $.ajax({
      url: 'reservation.php', // Replace with your PHP file
      data: $(this).serialize(),
      type: 'POST',
      success: function (data) {
        swal("Thank You!", "Your reservation request has been submitted. You will get the confirmation message shortly.", "success");
      },
      error: function (data) {
        swal("Oops...", "Something went wrong :(", "error");
      }
    });
  });

  // Contact Form Submission
  $('#contact-form').on('submit', function (e) {
    e.preventDefault(); // Prevent form submission and page refresh
    $.ajax({
      url: 'contact.php', // Replace with your PHP file
      data: $(this).serialize(),
      type: 'POST',
      success: function (data) {
        swal("Thank You!", "Your Message has been sent. Admin will respond to you shortly", "success");
      },
      error: function (data) {
        swal("Oops...", "Something went wrong :(", "error");
      }
    });
  });
});
