import React, { useEffect} from 'react'
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import banner from '../assets/img/products_banner.jpg';
import over_img1 from '../assets/img/products_over_img1.jpg';
import over_img2 from '../assets/img/products_over_img2.jpg';
import discover1 from '../assets/img/discover1.png';
import discover2 from '../assets/img/discover2.png';
import discover3 from '../assets/img/discover3.png';
import discover4 from '../assets/img/discover4.png';
import discover5 from '../assets/img/discover5.png';
import discover6 from '../assets/img/discover6.png';

function Products() {
    useEffect(() => {
        document.title = 'MS Agro Exim | Our Products';
        window.scrollTo(0, 0); // Scroll to the top of the page
        
      }, []);
  return (
    <>
      <Navbar />
    <section className="banner" style={{backgroundImage:`url(${banner})`}}>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="title-area-data">
                    <h2 className='text-white'>Our Products</h2>
                    {/* <p className='text-white'>Wholesome Wonders: A Gastronomic Adventure with Agricultural Treasures</p> */}
                    </div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/"><i className="fa-solid fa-house"></i> Home</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Our Products</li>
                    </ol>
                </div>
                <div className="col-lg-5">
                    <div className="row">
                    <div className="col-6">
                        <div className="title-area-img">
                            <img alt="title-area-img" src={over_img1}/>
                            {/* <img alt="pata" className="pata" src="assets/img/pata.png" /> */}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="title-area-img two">
                            <img alt="title-area-img" src={over_img2} />
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="gap">
        <div className="container">
            {/* start::menu 1 */}
            <div className="accordion" id="accordionExample1">
               <div className="row align-items-center discover-menu mt-5">
                  <div className="col-xl-4">
                     <div className="discover-img">
                           <img alt="discover" src={discover1} width="100%"/>
                     </div>
                  </div>
                  <div className="col-xl-4">
                     <div className="discover">
                           <h4>Peanuts</h4>
                           <ul >
                              <li className="accordion-header w-100" id="h1">
                                 <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                                    <h6>Bold Peanuts</h6>
                                 </button>
                              </li>
                              <div id="collapse1" className="accordion-collapse collapse w-100" aria-labelledby="h1" data-bs-parent="#accordionExample1">
                                 <div className="accordion-body pt-0">
                                    <p className='my-2'>Bold peanuts normally used in oil, peanut butter.</p>
                                    <p className='my-2'>Bold Peanuts are nutrient-dense, containing more than 30 important elements and phytonutrients. Peanuts are high in niacin, folate, fibre, magnesium, Vitamin E, manganese, and phosphorus, among other nutrients.</p>
                                    <p className='my-2'>They also contain about 25% protein and are naturally free of trans fats and salt.</p>
                                 </div>
                              </div>
                              <li className="accordion-header" id="h2">
                                 <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                                    <h6>Java Peanuts</h6>
                                 </button>
                              </li>
                              <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="h2" data-bs-parent="#accordionExample1">
                                 <div className="accordion-body pt-0">
                                    <p className='my-2'>Java peanut use for direct hand to mouth, roundshape peanuts so use for coated peanuts</p>
                                    <p className='my-2'>Minerals such as potassium, manganese, iron, copper, and zinc are abundant in Java peanuts, which energise the body and protect it from sickness.</p>
                                    <p className='my-2'>Our peanuts surpass international quality standards by being the least moist and having the lowest aflatoxin levels.</p>
                                 </div>
                              </div>
                              <li className="accordion-header" id="h3">
                                 <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                                    <h6>TJ Peanuts</h6>
                                 </button>
                              </li>
                              <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="h3" data-bs-parent="#accordionExample1">
                                 <div className="accordion-body pt-0">
                                    <p className='my-2'>TJ Peanuts are smaller than bold types. The kernels are big in size, oval in shape and are covered with light, red-coloured skin, darkening with age.</p>
                                    <p className='my-2'>Annual production of TJ Peanuts in India exceed 6 million metric tonnes. 70% of the winter crop is the bold variety and 85% of the summer crop is Java variety.</p>
                                    <p className='my-2'>TJ Peanuts that are packed with minerals such as potassium, manganese, iron, copper and zinc, which energizes body and prevents from diseases. The oil content in a peanut is far greater than any other oilseed.</p>
                                 </div>
                              </div>
                              <li className="accordion-header" id="h4">
                                 <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                                    <h6>Peanut Ishell</h6>
                                 </button>
                              </li>
                              <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="h4" data-bs-parent="#accordionExample1">
                                 <div className="accordion-body pt-0">
                                    <p className='my-2'>Peanut shells are the main waste product of the peanut industry, making up 25–30% of the total weight of the legume. They are a fibrous and lignocellulosic material, made up of cellulose, hemicellulose, and lignin. They are also highly porous and can absorb around 198% of their weight in water at 72 hours.</p>
                                    <p className='my-2'>Peanut shells are a slow-degrading waste product. Some companies are experimenting with turning peanut shells into pellets that can be used for biofuel, soil additives, and more. Peanut shells can also be repurposed as mulch or kitty litter. </p>
                                 </div>
                              </div>
                           </ul>
                     </div>
                  </div>
                  <div className="col-xl-4">
                     <div className="discover">
                           <li className="accordion-header" id="h5">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                                 <h6>Blanched Whole Peanuts</h6>
                              </button>
                           </li>
                           <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="h5" data-bs-parent="#accordionExample1">
                              <div className="accordion-body pt-0">
                                 <p className='my-2'>Blanched whole peanuts are peanuts that have undergone a blanching process, which involves briefly boiling the peanuts in water and then removing their skins. The term "blanching" refers to the removal of the outer skin or seed coat of the peanuts, leaving the inner nut exposed. This process results in a smooth, cream-colored appearance for the peanuts.</p>
                                 <p className='my-2'>Blanched whole peanuts can be used in various culinary endeavors, including baking, cooking, snacking, and as ingredients in both sweet and savory dishes. They are commonly found in recipes for peanut butter, desserts, salads, and certain Asian cuisines.</p>
                              </div>   
                           </div>
                           <li className="accordion-header" id="h6">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
                                 <h6>Blanched Split Peanuts</h6>
                              </button>
                           </li>
                           <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="h6" data-bs-parent="#accordionExample1">
                              <div className="accordion-body pt-0">
                                 <p className='my-2'>Blanched split peanuts are peanuts that have undergone the blanching process, during which they are briefly boiled and then have their skins removed. The term "split" refers to the fact that the peanuts are then separated into halves, exposing the inner nut.</p>
                                 <p className='my-2'>Whether used as a topping, ingredient, or standalone snack, blanched split peanuts offer a distinct texture and appearance compared to whole peanuts with skins. Their versatility makes them a popular choice in the culinary world for a wide range of applications.</p>
                              </div>   
                           </div>
                           <li className="accordion-header" id="h7">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="true" aria-controls="collapse7">
                                 <h6>Peanuts Oil</h6>
                              </button>
                           </li>
                           <div id="collapse7" className="accordion-collapse collapse" aria-labelledby="h7" data-bs-parent="#accordionExample1">
                              <div className="accordion-body pt-0">
                                 <p className='my-2'>Peanut oil is extracted from peanuts, which are technically legumes rather than true nuts. The oil is commonly obtained through a process that involves crushing the peanuts and then using mechanical pressing or solvent extraction methods.</p>
                                 <p className='my-2'>Peanut oil is a popular choice for frying because of its high smoke point and mild flavor.</p>
                                 <p className='my-2'> Its ability to withstand high temperatures makes it suitable for stir-frying vegetables and meats.</p>
                              </div>   
                           </div>
                        </div>
                  </div>
               </div>
            </div>
            {/* end::menu 1 */}
            {/* start::menu 2 */}
            <div className="accordion" id="accordionExample2">
               <div className="row align-items-center discover-menu mt-5">
                  <div className="col-xl-4">
                     <div className="discover-img">
                        <img alt="discover" src={discover2} width="100%"/>
                     </div>
                  </div>
                  <div className="col-xl-4">
                     <div className="discover">
                        <h4>Spices </h4>
                        <ul>
                           <li className="accordion-header" id="h8">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="true" aria-controls="collapse8">
                                 <h6>Cumin Seed</h6>
                              </button>
                           </li>
                           <div id="collapse8" className="accordion-collapse collapse" aria-labelledby="h8" data-bs-parent="#accordionExample2">
                              <div className="accordion-body pt-0">
                                 <p className='my-2'>Cumin seeds can be used whole or ground, and they add depth and complexity to a wide range of recipes, including curries, stews, soups, and spice blends. Besides its culinary uses, cumin is also known for its potential health benefits. It contains antioxidants and may have anti-inflammatory properties. Additionally, cumin has been traditionally used to aid digestion.</p>
                                 <p className='my-2'>In summary, cumin seed is a versatile spice that enhances the flavor of many dishes and contributes to both the taste and health aspects of diverse cuisines.</p>
                              </div>   
                           </div>
                           <li className="accordion-header" id="h9">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="true" aria-controls="collapse9">
                                 <h6>Fenugreek Seed</h6>
                              </button>
                           </li>
                           <div id="collapse9" className="accordion-collapse collapse" aria-labelledby="h9" data-bs-parent="#accordionExample2">
                              <div className="accordion-body pt-0">
                                 <p className='my-2'>Fenugreek seeds come from the plant Trigonella foenum-graecum and are a popular spice with a slightly bitter taste. These small, yellow-brown seeds are commonly used in various culinary applications and are known for their distinct flavor profile.</p>
                                 <p className='my-2'>In terms of cuisine, fenugreek seeds are a staple in Indian, Middle Eastern, and North African cooking. They are often used in spice blends, such as curry powders, and can be found in pickles, chutneys, and vegetable dishes. The seeds have a slightly nutty and bitter taste, which mellows and transforms when cooked, adding a unique depth of flavor to dishes.</p>
                              </div>   
                           </div>
                           <li className="accordion-header" id="h10">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="true" aria-controls="collapse10">
                                 <h6>Fennel Seed</h6>
                              </button>
                           </li>
                           <div id="collapse10" className="accordion-collapse collapse" aria-labelledby="h10" data-bs-parent="#accordionExample2">
                              <div className="accordion-body pt-0">
                                 <p className='my-2'>In the culinary realm, fennel seeds are used in both savory and sweet dishes. </p>
                                 <p className='my-2'>They are a key ingredient in many Mediterranean and Indian recipes, contributing their unique aromatic and slightly sweet taste to sausages, bread, salads, and desserts.</p>
                                 <p className='my-2'>Fennel seeds can be used whole or ground, adding depth and complexity to a wide range of dishes.</p>
                              </div>   
                           </div>
                           <li className="accordion-header" id="h11">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-expanded="true" aria-controls="collapse11">
                                 <h6>Coriander Seeds</h6>
                              </button>
                           </li>
                           <div id="collapse11" className="accordion-collapse collapse" aria-labelledby="h11" data-bs-parent="#accordionExample2">
                              <div className="accordion-body pt-0">
                                 <p className='my-2'>Coriander seeds are the dried seeds of the coriander plant (Coriandrum sativum), which belongs to the parsley family. </p>
                                 <p className='my-2'>They are a common ingredient in spice blends, such as curry powders, and are frequently used in Indian, Middle Eastern, and Latin American cuisines.</p>
                                 <p className='my-2'>Coriander seeds contribute a unique and versatile taste to soups, stews, curries, pickles, and various meat and vegetable dishes.</p>
                              </div>   
                           </div>
                           <li className="accordion-header" id="h12">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse12" aria-expanded="true" aria-controls="collapse12">
                                 <h6>Turmeric</h6>
                              </button>
                           </li>
                           <div id="collapse12" className="accordion-collapse collapse" aria-labelledby="h12" data-bs-parent="#accordionExample2">
                              <div className="accordion-body pt-0">
                                 <p className='my-2'>Turmeric imparts a golden hue to dishes and is commonly used in soups, stews, rice dishes, and sauces.</p>
                                 <p className='my-2'>Beyond its culinary uses, turmeric has been recognized for its potential health-promoting properties. </p>
                                 <p className='my-2'>Turmeric has been traditionally used in Ayurvedic and traditional medicine for various purposes, including the management of inflammatory conditions and digestive issues.</p>
                              </div>   
                           </div>
                        </ul>
                     </div>
                  </div>
                  <div className="col-xl-4">
                     <div className="discover">
                        <li className="accordion-header" id="h13">
                           <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse13" aria-expanded="true" aria-controls="collapse13">
                              <h6>Red Chilly</h6>
                           </button>
                        </li>
                        <div id="collapse13" className="accordion-collapse collapse" aria-labelledby="h13" data-bs-parent="#accordionExample2">
                           <div className="accordion-body pt-0">
                              <p className='my-2'>Red chili, often simply referred to as "chili" or "chili pepper," is a spicy and pungent fruit of plants belonging to the Capsicum genus.</p>
                              <p className='my-2'>Red chilies are not only valued for their ability to add heat but also for their potential health benefits.</p>
                              <p className='my-2'>It's important to note that the heat level of red chilies can vary significantly, and different varieties may have distinct flavor profiles.</p>
                           </div>   
                        </div>
                        <li className="accordion-header" id="h14">
                           <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse14" aria-expanded="true" aria-controls="collapse14">
                              <h6>Black Cumin</h6>
                           </button>
                        </li>
                        <div id="collapse14" className="accordion-collapse collapse" aria-labelledby="h14" data-bs-parent="#accordionExample2">
                           <div className="accordion-body pt-0">
                              <p className='my-2'>Black cumin, also known as Nigella sativa, is a flowering plant that produces small black seeds, often referred to as black cumin seeds or black seed.</p>
                              <p className='my-2'>The seeds of black cumin have been used for centuries in various traditional medicinal practices and cuisines.</p>
                              <p className='my-2'>These seeds have a unique and intense flavor, often described as peppery with a hint of onion, and they are used as a spice in certain culinary dishes.</p>
                           </div>   
                        </div>
                        <li className="accordion-header" id="h15">
                           <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse15" aria-expanded="true" aria-controls="collapse15">
                              <h6>Asaliya Seed</h6>
                           </button>
                        </li>
                        <div id="collapse15" className="accordion-collapse collapse" aria-labelledby="h15" data-bs-parent="#accordionExample2">
                           <div className="accordion-body pt-0">
                              <p className='my-2'>Asaliya seeds, also known as garden cress seeds or Halim seeds, come from the plant Lepidium sativum.</p>
                              <p className='my-2'>These small, reddish-brown seeds are commonly used in culinary and medicinal applications in various cultures.</p>
                              <p className='my-2'>Asaliya seeds are rich in nutrients, including iron, folic acid, and vitamins. They are often considered beneficial for overall health.</p>
                           </div>   
                        </div>
                        <li className="accordion-header" id="h16">
                           <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16" aria-expanded="true" aria-controls="collapse16">
                              <h6>Chilly Seed</h6>
                           </button>
                        </li>
                        <div id="collapse16" className="accordion-collapse collapse" aria-labelledby="h16" data-bs-parent="#accordionExample2">
                           <div className="accordion-body pt-0">
                              <p className='my-2'>Chili seeds are used for growing new chili plants. They can be collected from mature chili peppers and planted to cultivate a new crop.</p>
                              <p className='my-2'>While the seeds themselves are not typically consumed in cooking, the flesh around the seeds is what holds most of the heat in a chili pepper.</p>
                              <p className='my-2'>Chili peppers, including their seeds, are rich in vitamins (such as vitamin C) and antioxidants.</p>
                           </div>   
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* end::menu 2 */}
            {/* start::menu 3 */}
            <div className="accordion" id="accordionExample3">
               <div className="row align-items-center discover-menu mt-5">
                  <div className="col-xl-4">
                     <div className="discover-img">
                        <img alt="discover" src={discover3} width="100%"/>
                     </div>
                  </div>
                  <div className="col-xl-4">
                     <div className="discover">
                        <h4>Pulses </h4>
                        <ul>
                           <li className="accordion-header" id="h17">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse17" aria-expanded="true" aria-controls="collapse17">
                                 <h6>Desi Chickpeas</h6>
                              </button>
                           </li>
                           <div id="collapse17" className="accordion-collapse collapse" aria-labelledby="h17" data-bs-parent="#accordionExample3">
                              <div className="accordion-body pt-0">
                                 <p className='my-2'>Desi chickpeas, also known as Bengal gram or kala chana, are a variety of chickpeas (Cicer arietinum) that are smaller, darker, and have a rough coat compared to the more common Kabuli chickpeas. </p>
                                 <p className='my-2'>In addition to being a staple in various cuisines, desi chickpeas have been used traditionally in Ayurvedic medicine for their potential health benefits, including digestive support and as a source of energy.</p>
                                 <p className='my-2'>Desi chickpeas are a versatile and nutritious ingredient, adding both flavor and substance to a wide range of dishes.</p>
                              </div>   
                           </div>
                           <li className="accordion-header" id="h18">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse18" aria-expanded="true" aria-controls="collapse18">
                                 <h6>Kabuli Chickpeas</h6>
                              </button>
                           </li>
                           <div id="collapse18" className="accordion-collapse collapse" aria-labelledby="h18" data-bs-parent="#accordionExample3">
                              <div className="accordion-body pt-0">
                                 <p className='my-2'>Kabuli chickpeas (Cicer arietinum) are a larger and lighter-colored variety of chickpeas compared to their smaller, darker counterpart, the Desi chickpeas.</p>
                                 <p className='my-2'>Kabuli chickpeas are often used to make hummus, a popular Middle Eastern dip.</p>
                                 <p className='my-2'>Kabuli chickpeas contain essential nutrients, including iron, magnesium, and vitamin B6.</p>
                              </div>   
                           </div>
                           <li className="accordion-header" id="h19">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse19" aria-expanded="true" aria-controls="collapse19">
                                 <h6>Desi Chickpeas Split</h6>
                              </button>
                           </li>
                           <div id="collapse19" className="accordion-collapse collapse" aria-labelledby="h19" data-bs-parent="#accordionExample3">
                              <div className="accordion-body pt-0">
                                 <p className='my-2'>Desi chickpeas split, also known as chana dal, refer to the split and skinned version of desi chickpeas (Bengal gram or kala chana). </p>
                                 <p className='my-2'>Chana dal is a common ingredient in various Indian dishes, particularly in lentil-based curries and stews.</p>
                                 <p className='my-2'>It is rich in dietary fiber, promoting digestive health.</p>
                              </div>   
                           </div>
                           <li className="accordion-header" id="h20">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse20" aria-expanded="true" aria-controls="collapse20">
                                 <h6>Moong Beans</h6>
                              </button>
                           </li>
                           <div id="collapse20" className="accordion-collapse collapse" aria-labelledby="h20" data-bs-parent="#accordionExample3">
                              <div className="accordion-body pt-0">
                                 <p className='my-2'>Moong beans, also known as mung beans or green gram, are small, green, oval-shaped legumes belonging to the legume family. </p>
                                 <p className='my-2'>Moong beans are often used to make dal, a traditional Indian lentil soup.</p>
                                 <p className='my-2'>The fiber content in moong beans can aid in digestion and promote a feeling of fullness.</p>
                              </div>   
                           </div>
                        </ul>
                     </div>
                  </div>
                  <div className="col-xl-4">
                     <div className="discover">
                        <li className="accordion-header" id="h21">
                           <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse21" aria-expanded="true" aria-controls="collapse21">
                              <h6>Moong Dal Split</h6>
                           </button>
                        </li>
                        <div id="collapse21" className="accordion-collapse collapse" aria-labelledby="h21" data-bs-parent="#accordionExample3">
                           <div className="accordion-body pt-0">
                              <p className='my-2'>Moong dal split, also known as split yellow mung beans, refers to the hulled and split version of moong beans (green gram). </p>
                              <p className='my-2'>Moong dal split typically requires a shorter cooking time compared to whole moong beans. </p>
                              <p className='my-2'>It is relatively low in fat and can be a healthy addition to a balanced diet.</p>
                           </div>   
                        </div>
                        <li className="accordion-header" id="h22">
                           <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse22" aria-expanded="true" aria-controls="collapse22">
                              <h6>Urad Dal Split</h6>
                           </button>
                        </li>
                        <div id="collapse22" className="accordion-collapse collapse" aria-labelledby="h22" data-bs-parent="#accordionExample3">
                           <div className="accordion-body pt-0">
                              <p className='my-2'>Urad dal split is typically white or cream in color.</p>
                              <p className='my-2'>Being split and hulled, urad dal has a smooth texture.</p>
                              <p className='my-2'>It contains iron, contributing to the body's iron needs.</p>
                           </div>   
                        </div>
                        <li className="accordion-header" id="h23">
                           <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse23" aria-expanded="true" aria-controls="collapse23">
                              <h6>Cowpeas</h6>
                           </button>
                        </li>
                        <div id="collapse23" className="accordion-collapse collapse" aria-labelledby="h23" data-bs-parent="#accordionExample3">
                           <div className="accordion-body pt-0">
                              <p className='my-2'>Cowpeas are small, kidney-shaped beans with a black spot on one end.</p>
                              <p className='my-2'>The color of cowpeas varies and can include shades of white, cream, brown, and red.</p>
                              <p className='my-2'>Cowpeas are commonly used in soups, stews, and chili for their rich flavor and nutritional value.</p>
                           </div>   
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* end::menu 3 */}
            {/* start::menu 4 */}
            <div className="accordion" id="accordionExample4">
               <div className="row align-items-center discover-menu mt-5">
                  <div className="col-xl-4">
                     <div className="discover-img">
                        <img alt="discover" src={discover4} width="100%"/>
                     </div>
                  </div>
                  <div className="col-xl-4">
                     <div className="discover">
                        <h4>Grains </h4>
                        <ul>
                           <li className="accordion-header" id="h24">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse24" aria-expanded="true" aria-controls="collapse24">
                                 <h6>Green Millet</h6>
                              </button>
                           </li>
                           <div id="collapse24" className="accordion-collapse collapse" aria-labelledby="h24" data-bs-parent="#accordionExample4">
                              <div className="accordion-body pt-0">
                                 <p className='my-2'>Green millet, scientifically known as Setaria italica, is a small-seeded grass that is cultivated as a cereal crop. It is commonly referred to as "green millet" due to the color of its seeds.</p>
                                 <p className='my-2'>Green millet is often ground into flour, which is used to make unleavened flatbreads, such as roti or bhakri.</p>
                                 <p className='my-2'>The carbohydrates in green millet provide a good source of energy.</p>
                              </div>   
                           </div>
                           <li className="accordion-header" id="h25">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse25" aria-expanded="true" aria-controls="collapse25">
                                 <h6>Yellow Millet</h6>
                              </button>
                           </li>
                           <div id="collapse25" className="accordion-collapse collapse" aria-labelledby="h25" data-bs-parent="#accordionExample4">
                              <div className="accordion-body pt-0">
                                 <p className='my-2'>The seeds of yellow millet are typically yellow or golden, giving it the name "yellow millet." The seeds are small and round.</p>
                                 <p className='my-2'>Yellow millet grains can be cooked and served as a whole grain, similar to rice or quinoa.</p>
                                 <p className='my-2'>Yellow millet is often ground into flour, which is used to make various traditional dishes, including flatbreads, porridge, and snacks.</p>
                              </div>   
                           </div>
                           <li className="accordion-header" id="h26">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse26" aria-expanded="true" aria-controls="collapse26">
                                 <h6>Sorgum</h6>
                              </button>
                           </li>
                           <div id="collapse26" className="accordion-collapse collapse" aria-labelledby="h26" data-bs-parent="#accordionExample4">
                              <div className="accordion-body pt-0">
                                 <p className='my-2'>Sorghum (Sorghum bicolor) is a versatile and widely cultivated cereal grain that belongs to the grass family Poaceae.</p>
                                 <p className='my-2'>Sorghum is naturally gluten-free, making it suitable for those with gluten intolerance or celiac disease.</p>
                                 <p className='my-2'>The complex carbohydrates in sorghum provide a steady release of energy.</p>
                              </div>   
                           </div>
                        </ul>
                     </div>
                  </div>
                  <div className="col-xl-4">
                     <div className="discover">
                        <li className="accordion-header" id="h27">
                           <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse27" aria-expanded="true" aria-controls="collapse27">
                              <h6>Corn / Maize</h6>
                           </button>
                        </li>
                        <div id="collapse27" className="accordion-collapse collapse" aria-labelledby="h27" data-bs-parent="#accordionExample4">
                           <div className="accordion-body pt-0">
                              <p className='my-2'>Corn, also known as maize (Zea mays), is a cereal grain that originated in Mesoamerica and has become a staple food in many parts of the world.</p>
                              <p className='my-2'>It is one of the most widely grown and consumed grains globally, and it serves various culinary, industrial, and nutritional purposes.</p>
                              <p className='my-2'>There are various varieties of corn, including sweet corn (consumed as a vegetable), field corn (used for animal feed and industrial purposes), and popcorn (a type of corn that pops when heated).</p>
                           </div>   
                        </div>
                        <li className="accordion-header" id="h28">
                           <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse28" aria-expanded="true" aria-controls="collapse28">
                              <h6>Rice</h6>
                           </button>
                        </li>
                        <div id="collapse28" className="accordion-collapse collapse" aria-labelledby="h28" data-bs-parent="#accordionExample4">
                           <div className="accordion-body pt-0">
                              <p className='my-2'>Rice is a staple food that serves as a primary source of nutrition for a significant portion of the world's population.</p>
                              <p className='my-2'>Rice is characterized by small, elongated grains.</p>
                              <p className='my-2'>Common varieties include long-grain, medium-grain, and short-grain rice.</p>
                              <p className='my-2'>Rice holds cultural significance in many societies. It is a staple in Asian cuisines and is central to various rituals and traditions.</p>
                           </div>   
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* end::menu 4 */}
            {/* start::menu 5 */}
            <div className="accordion" id="accordionExample5">
               <div className="row align-items-center discover-menu mt-5">
                  <div className="col-xl-4">
                     <div className="discover-img">
                        <img alt="discover" src={discover5} width="100%"/>
                     </div>
                  </div>
                  <div className="col-xl-4">
                     <div className="discover">
                        <h4>Oil Seeds </h4>
                        <ul>
                           <li className="accordion-header" id="h29">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse29" aria-expanded="true" aria-controls="collapse29">
                                 <h6>Black Mustard</h6>
                              </button>
                           </li>
                           <div id="collapse29" className="accordion-collapse collapse" aria-labelledby="h29" data-bs-parent="#accordionExample5">
                              <div className="accordion-body pt-0">
                                 <p className='my-2'>The seeds are small, round, and range in color from dark brown to black.</p>
                                 <p className='my-2'>Black mustard seeds have a strong, pungent flavor.</p>
                                 <p className='my-2'>Black mustard seeds are used to produce mustard oil, which is used in cooking and as a flavoring agent.</p>
                              </div>   
                           </div>
                           <li className="accordion-header" id="h30">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse30" aria-expanded="true" aria-controls="collapse30">
                                 <h6>Yellow Mustard</h6>
                              </button>
                           </li>
                           <div id="collapse30" className="accordion-collapse collapse" aria-labelledby="h30" data-bs-parent="#accordionExample5">
                              <div className="accordion-body pt-0">
                                 <p className='my-2'>The seeds are small, round, and light yellow to beige in color. Yellow mustard is known for its mild flavor and is a common condiment used in various cuisines.</p>
                                 <p className='my-2'>Yellow mustard seeds have a mild, slightly tangy flavor.</p>
                                 <p className='my-2'>Yellow mustard seeds are ground and mixed with vinegar, water, and other seasonings to create the popular yellow mustard sauce.</p>
                              </div>   
                           </div>
                        </ul>
                     </div>
                  </div>
                  <div className="col-xl-4">
                     <div className="discover">
                        <li className="accordion-header" id="h31">
                           <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse31" aria-expanded="true" aria-controls="collapse31">
                              <h6>Sesame Seed</h6>
                           </button>
                        </li>
                        <div id="collapse31" className="accordion-collapse collapse" aria-labelledby="h31" data-bs-parent="#accordionExample5">
                           <div className="accordion-body pt-0">
                              <p className='my-2'>Sesame seeds are tiny, flat, oval seeds obtained from the flowering plant Sesamum indicum. </p>
                              <p className='my-2'>Sesame seeds can vary in color, including white, beige, tan, gold, brown, and black.</p>
                              <p className='my-2'>Extracted from sesame seeds, sesame oil is a flavorful cooking oil used in various cuisines.</p>
                           </div>   
                        </div>
                        <li className="accordion-header" id="h32">
                           <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse32" aria-expanded="true" aria-controls="collapse32">
                              <h6>Flax Seed</h6>
                           </button>
                        </li>
                        <div id="collapse32" className="accordion-collapse collapse" aria-labelledby="h32" data-bs-parent="#accordionExample5">
                           <div className="accordion-body pt-0">
                              <p className='my-2'>Flax seeds can be brown or golden in color. They are small, flat, and oval-shaped seeds. Flax seeds have a mild, nutty flavor.</p>
                              <p className='my-2'>Flaxseed oil, extracted from the seeds, is used in salad dressings and as a nutritional supplement. It is important to note that flaxseed oil should not be used for cooking, as it has a low smoke point.</p>
                              <p className='my-2'>They provide a plant-based source of protein.</p>
                           </div>   
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* end::menu 5 */}
            {/* start::menu 6 */}
            <div className="accordion" id="accordionExample6">
               <div className="row align-items-center discover-menu mt-5">
                  <div className="col-xl-4">
                     <div className="discover-img">
                        <img alt="discover" src={discover6} width="100%"/>
                     </div>
                  </div>
                  <div className="col-xl-4">
                     <div className="discover">
                        <h4>Other Agro Commodities</h4>
                        <ul>
                           <li className="accordion-header" id="h33">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse33" aria-expanded="true" aria-controls="collapse33">
                                 <h6>Roasted Gram Whole</h6>
                              </button>
                           </li>
                           <div id="collapse33" className="accordion-collapse collapse" aria-labelledby="h33" data-bs-parent="#accordionExample6">
                              <div className="accordion-body pt-0">
                                 <p className='my-2'>Roasted gram whole retains its round and beige appearance but transforms into a crunchy texture after roasting.</p>
                                 <p className='my-2'>The roasting process gives roasted gram whole a crispy and crunchy texture, making it an enjoyable snack.</p>
                                 <p className='my-2'>It is often included in trail mixes along with nuts, seeds, and dried fruits for a satisfying and nutritious snack.</p>
                              </div>   
                           </div>
                           <li className="accordion-header" id="h34">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse34" aria-expanded="true" aria-controls="collapse34">
                                 <h6>Roasted Gram Split</h6>
                              </button>
                           </li>
                           <div id="collapse34" className="accordion-collapse collapse" aria-labelledby="h34" data-bs-parent="#accordionExample6">
                              <div className="accordion-body pt-0">
                                 <p className='my-2'>Roasted gram whole retains its round and beige appearance but transforms into a crunchy texture after roasting.</p>
                                 <p className='my-2'>The roasting process gives roasted gram whole a crispy and crunchy texture, making it an enjoyable snack.</p>
                                 <p className='my-2'>It is often included in trail mixes along with nuts, seeds, and dried fruits for a satisfying and nutritious snack.</p>
                              </div>   
                           </div>
                        </ul>
                     </div>
                  </div>
                  <div className="col-xl-4">
                     <div className="discover">
                        <li className="accordion-header" id="h35">
                           <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse35" aria-expanded="true" aria-controls="collapse35">
                              <h6>Dry Coconut Copra</h6>
                           </button>
                        </li>
                        <div id="collapse35" className="accordion-collapse collapse" aria-labelledby="h35" data-bs-parent="#accordionExample6">
                           <div className="accordion-body pt-0">
                              <p className='my-2'>The term "copra" specifically refers to the dried coconut kernel, and it plays a crucial role in the production of coconut oil. Copra is widely used in various culinary and industrial applications due to its rich oil content and versatility.</p>
                              <p className='my-2'>Dry coconut copra has a brown, fibrous appearance, and it is typically hard and dry.</p>
                              <p className='my-2'>Copra is rich in coconut oil, and its extraction is a primary source of this edible oil.</p>
                           </div>   
                        </div>
                        <li className="accordion-header" id="h36">
                           <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse36" aria-expanded="true" aria-controls="collapse36">
                              <h6>Chia Seed</h6>
                           </button>
                        </li>
                        <div id="collapse36" className="accordion-collapse collapse" aria-labelledby="h36" data-bs-parent="#accordionExample6">
                           <div className="accordion-body pt-0">
                              <p className='my-2'>Chia seeds have been consumed for centuries, particularly in Latin American cultures, and they have become widely recognized for their health benefits.</p>
                              <p className='my-2'>Chia seeds are typically black or white. The black chia seeds are the most common variety.</p>
                              <p className='my-2'>Chia seeds are high in soluble and insoluble fiber, promoting digestive health.</p>
                           </div>   
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/* end::menu 6 */}

        </div>
    </section>
    <Footer />
    </>
  )
}

export default Products;
