import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo2 from '../assets/img/agro_logo1.png';
import footerBackImg from '../assets/img/footerBackImg.jpg';
import XpertLab from '../assets/img/XpertLab.png';
import { apiHeader, Post } from '../config/Reausable';

function Footer() {
    
    const [address,setAddress] = useState('');
    const [email,setEmail] = useState('');
    const [mobileNumber,setMobileNumber] = useState('');

    // fetch contact detail api call
    const fetchContactDetail = async() =>{

    var response = await Post('viewcontactdetail.php',{},apiHeader(false))
    if (response.status == '200')
    {
        if (response.data.status == '200')
        {

            console.log('check view contract details list' ,response.data.data);
            var contactData = response.data.data;
            setAddress(contactData[0].address);
            setEmail(contactData[0].email);
            setMobileNumber(contactData[0].mobileNumber);
        }
        else
        {
            console.log('Contact Details Not found',response);
        }
    }
    else
    {
        console.log('Contact Details Not Found In Api');
    }
    }
    useEffect(() => {
        fetchContactDetail();
    }, []);

    /* 11. progress go to top */
  let calcScrollValue = () => {
    let scrollProgress = document.getElementById('progress');
    let pos = document.documentElement.scrollTop;
    let calcHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    let scrollValue = Math.round((pos * 100) / calcHeight);
    if (pos > 100) {
      scrollProgress.style.display = 'grid';
    } else {
      scrollProgress.style.display = 'none';
    }
    scrollProgress.addEventListener('click', () => {
      document.documentElement.scrollTop = 0;
    });
    scrollProgress.style.background = `conic-gradient(#019745 ${scrollValue}%, #d7d7d7 ${scrollValue}%)`;
  };
 
  window.onscroll = calcScrollValue;
  window.onload = calcScrollValue;

  const [year, setYear] = useState("");
  useEffect(() => {
    const d = new Date();
    setYear(d.getFullYear());
  }, []);
 
  return (
    <>
        <footer style={{backgroundImage: `url(${footerBackImg})`,backgroundColor: "#f5f8fd"}}>
        <div className="container">
            <div className="row">
                <div className="col-xl-4 col-lg-6">
                    <div className="logo-white" style={{backgroundColor:"white"}}>
                    <Link to="/"><img alt="logo-white" src={logo2} style={{width:"50%"}}/></Link>
                    </div>
                </div>
                <div className="col-xl-2 col-lg-3 col-md-6">
                    <div className="link-about">
                    <h3>menu</h3>
                    <ul>
                        <li><i className="fa-solid fa-angle-right"></i><Link to="/">Home</Link></li>
                        <li><i className="fa-solid fa-angle-right"></i><Link to="/companies">Our Companies</Link></li>
                        <li><i className="fa-solid fa-angle-right"></i><Link to="/certificates">Certificates</Link></li>
                        <li><i className="fa-solid fa-angle-right"></i><Link to="/products">Our Products</Link></li>
                    </ul>
                    </div>
                </div>
                <div className="col-xl-2 col-lg-3 col-md-6">
                    <div className="link-about">
                    <h3>About</h3>
                    <ul>
                        <li><i className="fa-solid fa-angle-right"></i><Link to="/aboutus">About Us</Link></li>
                        <li><i className="fa-solid fa-angle-right"></i><Link to="/contact">Contact</Link></li>
                    </ul>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6">
                    <div className="link-about">
                        {/* <h3>Newsletter</h3>
                        <p>Get recent news and updates.</p>
                        <form className="footer-form">   
                            <input type="text" name="Enter Your Email Address" placeholder="Enter Your Email Address..." />
                            <button className="button">Subscribe</button>
                        </form> */}
                        <h3>Contact Info</h3>
                        <div className="content-header me-5">
                            <i className='p-2'>
                                <svg height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg"><g id="_16-Smartphone" data-name="16-Smartphone"><path d="m23 2h-14a3 3 0 0 0 -3 3v22a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-22a3 3 0 0 0 -3-3zm-5.39 2-.33 1h-2.56l-.33-1zm6.39 23a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1-1v-22a1 1 0 0 1 1-1h3.28l.54 1.63a2 2 0 0 0 1.9 1.37h2.56a2 2 0 0 0 1.9-1.37l.54-1.63h3.28a1 1 0 0 1 1 1z"/><path d="m17 24h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2z"/></g></svg>
                            </i><p className='text-white'>Phone:</p><Link to={`tel:+91${mobileNumber != "" && mobileNumber}`}>+91 {mobileNumber != "" && mobileNumber}</Link>
                        </div>
                        <div className="content-header my-2">
                            <i className='p-2'>
                                <svg height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg"><g id="_01-Email" data-name="01-Email"><path d="m29.61 12.21-13-10a1 1 0 0 0 -1.22 0l-13 10a1 1 0 0 0 -.39.79v14a3 3 0 0 0 3 3h22a3 3 0 0 0 3-3v-14a1 1 0 0 0 -.39-.79zm-13.61-7.95 11.36 8.74-11.36 8.74-11.36-8.74zm11 23.74h-22a1 1 0 0 1 -1-1v-12l11.39 8.76a1 1 0 0 0 1.22 0l11.39-8.76v12a1 1 0 0 1 -1 1z"/></g></svg>
                            </i><p className='text-white'>Email:</p><Link to={`mailto:${email != "" && email}`}>{email != "" && email}</Link>
                        </div>
                        <div className="content-header my-2">
                            <i className='p-2'>
                                <svg height="512" viewBox="0 0 32 32" width="512"><g id="_06-Location" data-name="06-Location"><path d="m25 21.61a1 1 0 1 0 -.84 1.82c1.37.57 1.84 1.23 1.84 1.57 0 1.19-4 3-10 3s-10-1.81-10-3c0-.34.47-1 1.8-1.57a1 1 0 1 0 -.8-1.82c-2.49 1.12-3 2.46-3 3.39 0 3.28 6 5 12 5s12-1.72 12-5c0-.93-.51-2.27-3-3.39z"/><path d="m15.45 25.83a1 1 0 0 0 1.1 0c.39-.26 9.45-6.38 9.45-13.83a10 10 0 0 0 -20 0c0 7.45 9.06 13.57 9.45 13.83zm.55-21.83a8 8 0 0 1 8 8c0 5.41-6.1 10.36-8 11.77-1.9-1.41-8-6.36-8-11.77a8 8 0 0 1 8-8z"/><path d="m21 12a5 5 0 1 0 -5 5 5 5 0 0 0 5-5zm-8 0a3 3 0 1 1 3 3 3 3 0 0 1 -3-3z"/></g></svg>
                            </i><p className='text-white'>Address: {address != "" && address}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bootem row">
                <div className="col-lg-4 text-center">
                    <h6><span style={{color:"white"}}>© {year} MS Agro Exim</span></h6>
                </div>
                <div className="col-lg-4 text-center">
                    <Link to="https://xpertlab.com/" target="_blank" style={{color:"white"}}>
                        <span>Design & Devloped By</span><img src={XpertLab} alt="XpertLab Logo" style={{width:'100px'}}/>
                    </Link>
                </div>
                
                <div className="col-lg-4">

                <div className="footer-social-media justify-content-xl-end justify-content-center" >
                        <li>
                            <Link to="/" target="_blank">
                                <i className="fa-brands fa-facebook-f"></i>
                            </Link>
                        </li>
                        <li>
                            <Link to="/" target="_blank">
                                <i className="fa-brands fa-instagram"></i>
                            </Link>
                        </li>
                        <li>
                            <Link to="/" target="_blank">
                            <i className="fa-brands fa-youtube"></i>
                            </Link>   
                        </li>
                        <li>
                            <Link to={`https://wa.me/${mobileNumber != "" && mobileNumber}`} target="_blank">
                            <i className="fa-brands fa-whatsapp"></i>
                            </Link>
                        </li>
                </div>
                </div>
            </div>
        </div>
        </footer>

        <div id="progress">
            <span id="progress-value"><i className="fa-solid fa-arrow-up"></i></span>
        </div>
    </>
  )
}

export default Footer;