import React, { useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { ToastContainer } from "react-toastify";
import { apiHeader, Post, toasterrormsg, toastsuccessmsg, } from '../config/Reausable';
import banner from '../assets/img/contact_banner.jpg';
import over_img1 from '../assets/img/contact_over_img1.jpg';
import over_img2 from '../assets/img/contact_over_img2.jpg';


function Contact() {
   // state start
   const [recaptchaValue, setRecaptchaValue] = useState('');
   const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false)

   const [email,setEmail] = useState('');
   const [mobileNumber,setMobileNumber] = useState('');

   const [fullNameInput,setFullNameInput] = useState('');
   const [emailInput,setEmailInput] = useState('');
   const [mobileNumberInput,setMobileNumberInput] = useState('');
   const [subjectInput,setSubjectInput] = useState('');
   const [messageInput,setMessageInput] = useState('');

   // set error state
   const [errors,setErrors]  = useState([{
      fullNameErr: '',
      emailErr:'',
      mobileNumberErr:'',
      subjectErr:'',
      messageErr:''
   }]);
     
  // remove validation function
  const removeError = (err) =>{
      var tmp = [...errors];
      tmp[0][err] = "";
      setErrors(tmp);
  }

   // fetch contact detail api call
   const fetchContactDetail = async() =>{

      var response = await Post('viewcontactdetail.php',{},apiHeader(false))
      if (response.status == '200')
      {
          if (response.data.status == '200')
          {

              console.log('check view contract details list' ,response.data.data);
              var contactData = response.data.data;
              setEmail(contactData[0].email);
              setMobileNumber(contactData[0].mobileNumber);
          }
          else
          {
              console.log('Contact Details Not found',response);
          }
      }
      else
      {
          console.log('Contact Details Not Found In Api');
      }
  }
  useEffect(() => {
      fetchContactDetail();
  }, []);

   const onRecaptchaChange = (value) => {
      setIsCaptchaSuccess(true)
      setRecaptchaValue(value);
   };
    
   useEffect(() => {
      document.title = 'MS Agro Exim | Contact';
      window.scrollTo(0, 0); // Scroll to the top of the page
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js?render=6Lf_vt8oAAAAAKzSaeZiywYcjXXN2LKuNOSD3RK-";
      script.async = true;
      script.onload = () => {
         // reCAPTCHA library has loaded
      };
      document.body.appendChild(script);
    }, []);
  
   // contact form validation
   const contactFormValidation = () => {
      var isValid = true;
      var errorTmp = [...errors];
      
      if (fullNameInput == "" || fullNameInput == undefined || fullNameInput == null) {
            errorTmp[0].fullNameErr = 'Please Enter Full Name.';
            isValid = false;
      } else if(!/^[\p{L}\s]*$/u.test(fullNameInput)){
            errorTmp[0].fullNameErr = 'Special Characters or Numbers not allowed.';
            isValid = false;
      }

      if (emailInput == "" || emailInput == undefined || emailInput == null) {
            errorTmp[0].emailErr = 'Please Enter Email.';
            isValid = false;
      } else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}(?:\.[A-Z]{2,})?$/i.test(emailInput)){
            errorTmp[0].emailErr = 'Please enter valid email.';
            isValid = false;
      }

      if (mobileNumberInput == "" || mobileNumberInput == undefined || mobileNumberInput == null) {
            errorTmp[0].mobileNumberErr = 'Please Enter Mobile Number.';
            isValid = false;
      } else if(!Number(mobileNumberInput)){
            errorTmp[0].mobileNumberErr = 'Only Numbers is allowed.';
            isValid = false;
      }else if((mobileNumberInput != "" && mobileNumberInput != undefined || mobileNumberInput != null) && mobileNumberInput.length < 8){
            errorTmp[0].mobileNumberErr = 'Minimum 8 Digit Required';
            isValid = false;
      }

      if (subjectInput == "" || subjectInput == undefined || subjectInput == null) {
         errorTmp[0].subjectErr = 'Please Enter Subject.';
         isValid = false;
      }

      if (messageInput == "" || messageInput == undefined || messageInput == null) {
         errorTmp[0].messageErr = 'Please Enter Message.';
         isValid = false;
      }
      setErrors(errorTmp);
      return isValid;
   }

   const handleSubmit = async(e) => {
      e.preventDefault();
      if(contactFormValidation()){

         if(isCaptchaSuccessful){
            
            let data = {
               
                  "fullName":fullNameInput,
                  "email":emailInput,
                  "mobileNumber":mobileNumberInput,
                  "subject":subjectInput,
                  "message":messageInput,
                  "sendEmail":"xpertlab.horse@gmail.com"
              
            }
            var response = await Post('adduser.php',data,apiHeader(false));
            if (response.status == '200')
            {
               if (response.data.status == '200')
               {
                  toastsuccessmsg("Message Send Successfully.");
                  setFullNameInput("");
                  setEmailInput("");
                  setMobileNumberInput("");
                  setSubjectInput("");
                  setMessageInput("");
               }
               else{
                  toasterrormsg(response.data.message);
               }
            }
            else{
               console.log("error in api call");
            }
            // console.log("Well, recaptcha is checked !",recaptchaValue);
         }
         else{
            toasterrormsg("Oops, you have to check the recaptcha !");
         }
      }
      // Handle your form submission here and verify the reCAPTCHA response.
   };

  return (
    <>
    <Navbar />
    <section className="banner" style={{backgroundImage:`url(${banner})`}}>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="title-area-data">
                    <h2 className='text-white'>Contact Us</h2>
                    {/* <p className='text-white'>Wholesome Wonders: A Gastronomic Adventure with Agricultural Treasures</p> */}
                    </div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/"><i className="fa-solid fa-house"></i> Home</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                    </ol>
                </div>
                <div className="col-lg-5">
                    <div className="row">
                    <div className="col-6">
                        <div className="title-area-img">
                            <img alt="title-area-img" src={over_img1}/>
                            {/* <img alt="pata" className="pata" src="assets/img/pata.png" /> */}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="title-area-img two">
                            <img alt="title-area-img" src={over_img2} />
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
<section className="gap"> 
   <div className="container">
      <div className="row">
         <div className="col-xl-12">
            <div className="get-in-touch">
               <h2>Get in Touch</h2>
               <ul className="booking">
               <li className="contact">
                  <i>
                     <svg height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg"><g id="_16-Smartphone" data-name="16-Smartphone"><path d="m23 2h-14a3 3 0 0 0 -3 3v22a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-22a3 3 0 0 0 -3-3zm-5.39 2-.33 1h-2.56l-.33-1zm6.39 23a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1-1v-22a1 1 0 0 1 1-1h3.28l.54 1.63a2 2 0 0 0 1.9 1.37h2.56a2 2 0 0 0 1.9-1.37l.54-1.63h3.28a1 1 0 0 1 1 1z"/><path d="m17 24h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2z"/></g></svg>
                  </i>
                  <div>
                     <span>Phone</span>
                     <Link to={`tel:+91${mobileNumber != "" && mobileNumber}`}><span>+91 {mobileNumber != "" && mobileNumber} </span></Link>
                  </div>
               </li>
               <li className="contact">
                  <i>
                     <svg height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg"><g id="_01-Email" data-name="01-Email"><path d="m29.61 12.21-13-10a1 1 0 0 0 -1.22 0l-13 10a1 1 0 0 0 -.39.79v14a3 3 0 0 0 3 3h22a3 3 0 0 0 3-3v-14a1 1 0 0 0 -.39-.79zm-13.61-7.95 11.36 8.74-11.36 8.74-11.36-8.74zm11 23.74h-22a1 1 0 0 1 -1-1v-12l11.39 8.76a1 1 0 0 0 1.22 0l11.39-8.76v12a1 1 0 0 1 -1 1z"></path></g></svg>
                  </i>
                  <div>
                     <span>Email</span>
                     <Link to={`mailto:${email != "" && email}`}><span style={{textTransform:"lowercase"}}>{email != "" && email}</span></Link>
                  </div>
               </li>
               </ul>
            </div>
            <div className="mapouter">
                <iframe 
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3537.202435608607!2d73.47203837546196!3d27.55622717626966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjfCsDMzJzIyLjQiTiA3M8KwMjgnMjguNiJF!5e0!3m2!1sen!2sin!4v1721395778690!5m2!1sen!2sin" 
               //  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3544.394639826314!2d78.13107787542197!3d27.332128141940927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39746367807efb2b%3A0x50daebdd30000000!2sM.S%20Agro%20Agency!5e0!3m2!1sen!2sin!4v1698646748268!5m2!1sen!2sin" 
                title="myFrame" width="600" height="700" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>
            <div className="align-items-center d-flex mt-3">
               {/* <i className="fa-solid fa-location-dot me-3"></i> */}
               {/* <p>Old Upleta Road, Near Bhola Patiya, Dhoraji - 360410. Dist. Rajkot(Gujarat)</p> */}
            </div>
            
         </div>
         
      </div>
      <div className="row mt-5">
        <div className="col-xl-12">
            <div className="get-in-touch">
               <h2>Have Question?</h2>
               <p>For all enquires, please contact us and one of our team will be be happy to help.</p>
            </div>
            <div id="contact-form" className="add-review leave-comment mt-4">
               <div className="row">
                  <div className="col-12 mb-3">
                     <input type="text" className='mb-0' name="complete_name" id="Complete_Name" placeholder="Full Name" value={fullNameInput} onChange={(e) => {setFullNameInput(e.target.value);removeError("fullNameErr");}} />
                     <div className='text-danger'>{errors[0].fullNameErr}</div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12 mb-3">
                     <input type="text" className='mb-0' name="email_address" placeholder="Email Address" id="email_address"  value={emailInput} onChange={(e) => {setEmailInput(e.target.value);removeError("emailErr");}}/>
                     <div className='text-danger'>{errors[0].emailErr}</div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12 mb-3">
                     <input type="text" className='mb-0' name="phone" placeholder="Phone No" value={mobileNumberInput} onChange={(e) => {setMobileNumberInput(e.target.value);removeError("mobileNumberErr");}}/>
                     <div className='text-danger'>{errors[0].mobileNumberErr}</div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12 mb-3">
                     <input type="text" className='mb-0' name="subject" placeholder="Subject" value={subjectInput} onChange={(e) => {setSubjectInput(e.target.value);removeError("subjectErr");}}/>
                     <div className='text-danger'>{errors[0].subjectErr}</div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12 mb-3">
                     <textarea placeholder="Message" className='mb-0' name="message" value={messageInput} onChange={(e) => {setMessageInput(e.target.value);removeError("messageErr");}}></textarea>
                     <div className='text-danger'>{errors[0].messageErr}</div>
                  </div>
               </div>
               <ReCAPTCHA
                  sitekey="6Lf_vt8oAAAAAKzSaeZiywYcjXXN2LKuNOSD3RK-"
                  onChange={onRecaptchaChange}
               />
               <button className="button my-3" type="submit" value="submit" onClick={handleSubmit}>
                  <span>send Message</span>
               </button>
            </div>
         </div>
      </div>
   </div>
</section>

<Footer /> 
<ToastContainer />

    </>
  )
}

export default Contact;
