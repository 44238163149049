import { post } from 'jquery';
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import logo from '../assets/img/agro_logo1.png';
import { apiHeader, Post } from '../config/Reausable';

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);

    const [email,setEmail] = useState('');
    const [mobileNumber,setMobileNumber] = useState('');
    // const [isActive, setIsActive] = useState(false);
  
    // const toggleActive = () => {
    //   setIsActive(!isActive);
    // };
    const toggleMobileNav = () => {
      setIsOpen(!isOpen);
    };
  
    const closeMobileNav = () => {
      setIsOpen(false);
    };
    
    window.addEventListener('scroll', () => {
        // document.querySelector('.top-header');
        const bottomBar = document.querySelector('.bottom-bar');
    
        // Get the scroll position
        const scrollY = window.scrollY || window.pageYOffset;
    
        // Define the scroll threshold when the header should become sticky
        const scrollThreshold = 0; // Adjust this value as needed
        
        if (scrollY > scrollThreshold) {
            // User scrolled past the threshold, make the bottom bar visible
            bottomBar.style.top = '0px';
            bottomBar.style.opacity = '1';
            bottomBar.style.visibility = 'visible';
        } else {
            // User scrolled back up, hide the bottom bar
            bottomBar.style.opacity = '1';
            bottomBar.style.visibility = 'visible';
            bottomBar.style.top = '55px';
        }
    });

    // fetch contact detail api call
    const fetchContactDetail = async() =>{

        var response = await Post('viewcontactdetail.php',{},apiHeader(false))
        if (response.status == '200')
        {
            if (response.data.status == '200')
            {

                var contactData = response.data.data;
                setEmail(contactData[0].email);
                setMobileNumber(contactData[0].mobileNumber);
            }
            else
            {
                console.log('Contact Details Not found',response);
            }
        }
        else
        {
            console.log('Contact Details Not Found In Api');
        }
    }
    useEffect(() => {
        fetchContactDetail();
    }, []);
  return (
    <>
    <div className="preloader"> 
        <div className="container">
        <div className="dot dot-1"></div>
        <div className="dot dot-2"></div>
        <div className="dot dot-3"></div>
        </div>
    </div>
    <header className="one">
        <div className="top-header">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-6">
                    <div className="d-flex align-items-center">
                        <div className="content-header me-5">
                            <i>
                                <svg height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg"><g id="_16-Smartphone" data-name="16-Smartphone"><path d="m23 2h-14a3 3 0 0 0 -3 3v22a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-22a3 3 0 0 0 -3-3zm-5.39 2-.33 1h-2.56l-.33-1zm6.39 23a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1-1v-22a1 1 0 0 1 1-1h3.28l.54 1.63a2 2 0 0 0 1.9 1.37h2.56a2 2 0 0 0 1.9-1.37l.54-1.63h3.28a1 1 0 0 1 1 1z"/><path d="m17 24h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2z"/></g></svg>
                            </i><h4>Phone:<Link to={`tel:+91${mobileNumber != "" && mobileNumber}`}>+91 {mobileNumber != "" && mobileNumber}</Link></h4>
                        </div>
                        <div className="content-header">
                            <i>
                                <svg height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg"><g id="_01-Email" data-name="01-Email"><path d="m29.61 12.21-13-10a1 1 0 0 0 -1.22 0l-13 10a1 1 0 0 0 -.39.79v14a3 3 0 0 0 3 3h22a3 3 0 0 0 3-3v-14a1 1 0 0 0 -.39-.79zm-13.61-7.95 11.36 8.74-11.36 8.74-11.36-8.74zm11 23.74h-22a1 1 0 0 1 -1-1v-12l11.39 8.76a1 1 0 0 0 1.22 0l11.39-8.76v12a1 1 0 0 1 -1 1z"/></g></svg>
                            </i><h4>Email:<Link to={`mailto:${email != "" && email}`}>{email != "" && email}</Link></h4>
                        </div>
                    </div>
                    </div>
                    <div className="col-xl-6">
                    <div className="d-flex align-items-center login">
                    <div className="nav-social-media">
                        <li>
                            <Link to="/" target="_blank">
                                <i className="fa-brands fa-facebook-f"></i>
                            </Link>
                        </li>
                        <li>
                            <Link to="/" target="_blank">
                                <i className="fa-brands fa-instagram"></i>
                            </Link>
                        </li>
                        <li>
                            <Link to="/" target="_blank">
                            <i className="fa-brands fa-youtube"></i>
                            </Link>   
                        </li>
                        <li>
                            <Link to={`https://wa.me/${mobileNumber != "" && mobileNumber}`} target="_blank">
                            <i className="fa-brands fa-whatsapp"></i>
                            </Link>
                        </li>
                    </div>
                    {/* <div className="register">
                        <i>
                            <svg clipRule="evenodd" fillRule="evenodd" height="512" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Approved-User"><path d="m10.105 22.3c.21-.482.511-.926.89-1.305.797-.797 1.878-1.245 3.005-1.245h4c1.127 0 2.208.448 3.005 1.245.379.379.68.823.89 1.305.166.379.608.553.988.387.379-.165.553-.608.387-.987-.285-.653-.691-1.253-1.204-1.766-1.078-1.078-2.541-1.684-4.066-1.684-1.3 0-2.7 0-4 0-1.525 0-2.988.606-4.066 1.684-.513.513-.919 1.113-1.204 1.766-.166.379.008.822.387.987.38.166.822-.008.988-.387z"/><path d="m16 8.25c-3.174 0-5.75 2.576-5.75 5.75s2.576 5.75 5.75 5.75 5.75-2.576 5.75-5.75-2.576-5.75-5.75-5.75zm0 1.5c2.346 0 4.25 1.904 4.25 4.25s-1.904 4.25-4.25 4.25-4.25-1.904-4.25-4.25 1.904-4.25 4.25-4.25z"/><path d="m26.609 12.25c.415 1.173.641 2.435.641 3.75 0 6.209-5.041 11.25-11.25 11.25s-11.25-5.041-11.25-11.25 5.041-11.25 11.25-11.25c1.315 0 2.577.226 3.75.641.39.138.819-.067.957-.457s-.067-.819-.457-.957c-1.329-.471-2.76-.727-4.25-.727-7.037 0-12.75 5.713-12.75 12.75s5.713 12.75 12.75 12.75 12.75-5.713 12.75-12.75c0-1.49-.256-2.921-.727-4.25-.138-.39-.567-.595-.957-.457s-.595.567-.457.957z"/><path d="m21.47 8.53 2 2c.293.293.767.293 1.06 0l4-4c.293-.292.293-.768 0-1.06-.292-.293-.768-.293-1.06 0l-3.47 3.469s-1.47-1.469-1.47-1.469c-.292-.293-.768-.293-1.06 0-.293.292-.293.768 0 1.06z"/></g></svg>
                        </i><Link to="/">Login / Register</Link>
                    </div> */}
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="bottom-bar">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-3">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="logo">
                            <Link to="/">
                                <img alt="logo" src={logo} style={{paddingTop:"2px",paddingBottom:"2px"}} />
                            </Link>
                        </div>
                        <div className="d-flex cart-checkout">
                            {/* <Link to="/">
                                <i>
                                    <svg enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g>
                                    <path d="m452 120h-60.946c-7.945-67.478-65.477-120-135.054-120s-127.109 52.522-135.054 120h-60.946c-11.046 0-20 8.954-20 20v352c0 11.046 8.954 20 20 20h392c11.046 0 20-8.954 20-20v-352c0-11.046-8.954-20-20-20zm-196-80c47.484 0 87.019 34.655 94.659 80h-189.318c7.64-45.345 47.175-80 94.659-80zm176 432h-352v-312h40v60c0 11.046 8.954 20 20 20s20-8.954 20-20v-60h192v60c0 11.046 8.954 20 20 20s20-8.954 20-20v-60h40z"></path></g></svg>
                                </i>
                            </Link> */}
                            <div className="bar-menu" onClick={toggleMobileNav}>
                                <i className="fa-solid fa-bars"></i>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-xl-6">
                    <nav className="navbar">
                        <ul className="navbar-links">
                            <li className="navbar-dropdown">
                            <Link to="/">Home</Link>
                            {/* <div className="dropdown">
                                <Link to="/">home 1</Link>
                                <Link to="/">home 2</Link>
                                <Link to="/">home 3</Link>
                            </div> */}
                            </li>
                            <li className="navbar-dropdown">
                            <Link to="/aboutus" style={{textWrap:"nowrap"}}>About Us</Link>
                            {/* <div className="dropdown">
                                <Link to="/Aboutrangoli">About Rangoli</Link>
                                <Link to="/Aboutowners">About Owners</Link>
                            </div> */}
                            </li>
                            <li className="navbar-dropdown">
                            <Link to="/companies" style={{textWrap:"nowrap"}}>Our Companies</Link>
                            </li>
                            <li className="navbar-dropdown">
                            <Link to="/certificates">Certificates</Link>
                            </li>
                            <li className="navbar-dropdown">
                            <Link to="/products" style={{textWrap:"nowrap"}}>Our Products</Link>
                            </li>
                            <li className="navbar-dropdown">
                            <Link to="/contact" style={{textWrap:"nowrap"}}>Contact</Link>
                            </li>
                        </ul>
                        </nav>
                    </div>
                    {/* <div className="col-lg-3">
                    <div className="hamburger-icon">
                        <div className="donation">

                        <Link to="/" className="pr-cart">

                        <svg id="Shoping-bags" enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g><path d="m452 120h-60.946c-7.945-67.478-65.477-120-135.054-120s-127.109 52.522-135.054 120h-60.946c-11.046 0-20 8.954-20 20v352c0 11.046 8.954 20 20 20h392c11.046 0 20-8.954 20-20v-352c0-11.046-8.954-20-20-20zm-196-80c47.484 0 87.019 34.655 94.659 80h-189.318c7.64-45.345 47.175-80 94.659-80zm176 432h-352v-312h40v60c0 11.046 8.954 20 20 20s20-8.954 20-20v-60h192v60c0 11.046 8.954 20 20 20s20-8.954 20-20v-60h40z"></path></g></svg>

                        </Link>

                        <div className="cart-popup">

                            <ul>

                            <li className="d-flex align-items-center position-relative">

                                <div className="p-img light-bg">

                                <img src="https://via.placeholder.com/75x75" alt="Product Img" />

                                </div>

                                <div className="p-data">

                                <h3 className="font-semi-bold">Brown Sandwich</h3>

                                <p className="theme-clr font-semi-bold">1 x ₹10.50</p>

                                </div>

                                <Link to="/" id="crosss"></Link>

                            </li>

                            <li className="d-flex align-items-center position-relative">

                                <div className="p-img light-bg">

                                <img src="https://via.placeholder.com/75x75" alt="Product Img" />

                                </div>

                                <div className="p-data">

                                <h3 className="font-semi-bold">Banana Leaves</h3>

                                <p className="theme-clr font-semi-bold">1 x ₹12.60</p>

                                </div>

                                <Link to="/" id="cross"></Link>

                            </li>

                            </ul>

                            <div className="cart-total d-flex align-items-center justify-content-between">

                                <span className="font-semi-bold">Total:</span>

                                <span className="font-semi-bold">₹23.10</span>

                            </div>

                            <div className="cart-btns d-flex align-items-center justify-content-between">

                                <Link className="font-bold" to="shop-cart.html">View Cart</Link>

                                <Link className="font-bold theme-bg-clr text-white checkout" to="cart-checkout.html">Checkout</Link>

                            </div>

                        </div>

                        </div>
                            <Link to="/" className="button">Reserve a Table</Link>
                    </div>
                    </div> */}
                </div>
        </div>  
        </div>
    <div
            className={`mobile-nav ${isOpen ? 'open' : ''} ${isOpen ? 'hmburger-menu' : ''}`}
            id="mobile-nav"
            style={{ display: isOpen ? 'block' : 'block' }}
            >
        <div className="res-log">
            <Link to="/">
            <img src={logo} alt="Responsive Logo" className="white-logo" style={{width:"20%"}}/>
            </Link>
        </div>
            <ul>
                <li><Link to="/">Home</Link></li>
                {/* <li className={`menu-item-has-children ${isActive ? 'active' : ''}`} onClick={toggleActive}><Link to="/">About</Link></li> */}
                <li><Link to="/aboutus">About Us</Link></li>
                <li><Link to="/companies">Our Companies</Link></li>
                <li><Link to="/certificates">Certificates</Link></li>
                <li><Link to="/products">Our Products</Link></li>
                <li><Link to="/contact">Contact</Link></li>
            </ul>
            <Link to="/" id="res-cross" onClick={closeMobileNav}></Link>
    </div>
    </header>
    </>
  )
}

export default Navbar;
