import React, { useEffect } from 'react';

import { Fancybox as NativeFancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

function Fancybox2(props) {
  const delegate = props.delegate || '[data-fancybox="factory-images"]';

  useEffect(() => {
    const opts = props.options || {};

    NativeFancybox.bind(delegate, opts);

    return () => {
      NativeFancybox.destroy();
    };
  }, [delegate, props.options]); // Include 'delegate' and 'props.options' in the dependency array

  return <>{props.children}</>;
}

export default Fancybox2;
