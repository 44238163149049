import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import { Link } from 'react-router-dom';
import Fancybox from '../components/Fancybox';
import Fancybox2 from '../components/Fancybox2';
import Footer from '../components/Footer';
import banner from '../assets/img/about_banner.jpg';
import over_img1 from '../assets/img/about_over_img1.jpg';
import over_img2 from '../assets/img/about_over_img2.jpg';
import award1 from '../assets/img/award_img1.jpeg';
import award2 from '../assets/img/award_img2.jpeg';
import factory1 from '../assets/img/factory1.jpg';
import factory2 from '../assets/img/factory2.jpeg';
import factory3 from '../assets/img/factory3.jpeg';
import factory4 from '../assets/img/factory4.jpeg';
import factory5 from '../assets/img/factory5.jpeg';

function Aboutus() {
    useEffect(() => {
        document.title = 'MS Agro Exim | About Us';
        window.scrollTo(0, 0); // Scroll to the top of the page
      }, []);
    const opts = {
        fullScreen: {
          autoStart: true, // Auto-start in full-screen mode
        },
        // Other options...
      };
  return (
    <>
    <Navbar />
    <section className="banner" style={{backgroundImage:`url(${banner})`}}>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="title-area-data">
                    <h2 className='text-white'>About Us</h2>
                    {/* <p className='text-white'>Wholesome Wonders: A Gastronomic Adventure with Agricultural Treasures</p> */}
                    </div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/"><i className="fa-solid fa-house"></i> Home</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">About Us</li>
                    </ol>
                </div>
                <div className="col-lg-5">
                    <div className="row">
                    <div className="col-6">
                        <div className="title-area-img">
                            <img alt="title-area-img" src={over_img1}/>
                            {/* <img alt="pata" className="pata" src="assets/img/pata.png" /> */}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="title-area-img two">
                            <img alt="title-area-img" src={over_img2} />
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div className="about-chef gap no-bottom">
        <div className="container">
            <h2 className="pb-3">About Us</h2>
            <p>With a rich experience of over five decades in the Agro commodities industry, MS Group is a leading supplier of a wide range of commodities that include peanuts, grains, spices, pulses, oil seeds and animal feed. With a firm commitment to quality and a strict adherence to standard business practices, MS Group has increased its offerings to over 35 commodities to customers not just in India, but across the globe.</p>
            <br /><br />
            <p>With state of the art processing facilities located in Nokha, Bikaner, Rajasthan is major agricultural center in India, MS Group provides end-to-end services and offers its products directly to customers. </p>
            <br /><br />
            <p>Our MS Group is a renowned name in agro and related commodities trading internationally. The group has specialized and created a niche for itself in the area of export of agro based commodities. The firm has five processing unit for manufacturing best quality agro products. </p>
            <br /><br />
            <ul>
                <li>1. SHYAM AGRO</li>
                <li>2. BALAJI UDYOG</li>
                <li>3. MARUTI UDHYOG</li>
                <li>4. BALAJI AGROTECH</li>
                <li>5. MURLI FLOUR MILLS PVT LTD</li>
            </ul>
            <br /><br />
            <p>Our MS group is a reliable supplier of quality products and is dedicated to meeting all its customer requirements and complying with the most demanding standards. We are deeply rooted to our values of commitment and integrity towards our client who ensures that we become their preferred support. We are one of the fast growing export – import oriented firm engaged in various types of food related commodity.</p>
            <br /><br />
            <ul>
                <li>1. Got award from Rajasthan Government in Highest Growth In Agro based business in whole Rajasthan</li>
                <li>2. Highest GroundNut Inshell Export Awared from IOPEPC 2022</li>
                <li>3. Highest GroundNut Inshell Export Awared from IOPEPC 2023</li>
            </ul>
        </div>
    </div>
    <div className="container gap no-bottom">
        <div className="heading-two">
            <h2>Awards</h2>
            <div className="line"></div>
        </div>
            <div className="row">
                
            <Fancybox options={opts}>
                <div className="col-lg-4 col-sm-6">
                    <div className="photo-gallery">
                        <div className="featured-imagebox featured-imagebox-gallery">
                        <a data-fancybox="gallery" href={award1} >
                            <div className="featured-link">
                                <div className="featured-thumbnail">                                                
                                    <img className="img-fluid" src={award1} alt="award-img" />
                                </div>
                                <div className="featured-overlay"></div>
                            </div>
                        </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div className="photo-gallery">
                        <div className="featured-imagebox featured-imagebox-gallery">
                        <a data-fancybox="gallery" href={award2}>
                            <div className="featured-link">
                                <div className="featured-thumbnail">                                                
                                    <img className="img-fluid" src={award2} alt="award-img" />
                                </div>
                                <div className="featured-overlay"></div>
                            </div>
                        </a>
                        </div>
                    </div>
                </div>
            </Fancybox>
            </div>

    </div>
   <div className="container gap">
        <div className="heading-two">
            <h2>Our Factory</h2>
            <div className="line"></div>
        </div>
            <div className="row">
                
            <Fancybox2 options={opts}>
                <div className="col-lg-4 col-sm-6">
                    <div className="photo-gallery">
                        <div className="featured-imagebox featured-imagebox-gallery">
                        <a data-fancybox="factory-images" href={factory1} >
                            <div className="featured-link">
                                <div className="featured-thumbnail">                                                
                                    <img className="img-fluid" src={factory1} alt="factory-img" />
                                </div>
                                <div className="featured-overlay"></div>
                            </div>
                        </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div className="photo-gallery">
                        <div className="featured-imagebox featured-imagebox-gallery">
                        <a data-fancybox="factory-images" href={factory2}>
                            <div className="featured-link">
                                <div className="featured-thumbnail">                                                
                                    <img className="img-fluid" src={factory2} alt="factory-img" />
                                </div>
                                <div className="featured-overlay"></div>
                            </div>
                        </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div className="photo-gallery">
                        <div className="featured-imagebox featured-imagebox-gallery">
                        <a data-fancybox="factory-images" href={factory3}>
                            <div className="featured-link">
                                <div className="featured-thumbnail">                                                
                                    <img className="img-fluid" src={factory3} alt="factory-img" />
                                    </div>
                                <div className="featured-overlay"></div>
                            </div>
                        </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div className="photo-gallery">
                        <div className="featured-imagebox featured-imagebox-gallery">
                        <a data-fancybox="factory-images" href={factory4}>
                            <div className="featured-link">
                                <div className="featured-thumbnail">                                                
                                    <img className="img-fluid" src={factory4} alt="factory-img" />
                                    </div>
                                <div className="featured-overlay"></div>
                            </div>
                        </a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div className="photo-gallery">
                        <div className="featured-imagebox featured-imagebox-gallery">
                        <a data-fancybox="factory-images" href={factory5}>
                            <div className="featured-link">
                                <div className="featured-thumbnail">                                                
                                    <img className="img-fluid" src={factory5} alt="factory-img" />
                                    </div>
                                <div className="featured-overlay"></div>
                            </div>
                        </a>
                        </div>
                    </div>
                </div>
            </Fancybox2>
            </div>
    </div>
    <Footer />
    </>
  )
}

export default Aboutus;