import React, { useEffect } from 'react';
import Navbar from '../components/Navbar';
import { Link } from 'react-router-dom';
import Fancybox from '../components/Fancybox';
import Footer from '../components/Footer';
import banner from '../assets/img/certificates_banner.jpg';
import over_img1 from '../assets/img/certificates_over_img1.jpg';
import over_img2 from '../assets/img/certificates_over_img2.jpg';
import certificates1 from '../assets/img/certificates1.jpg';
import certificates2 from '../assets/img/certificates2.jpg';
import certificates3 from '../assets/img/certificates3.png';
import certificates4 from '../assets/img/certificates4.png';
import certificates5 from '../assets/img/certificates5.png';

function Certificates() {
    useEffect(() => {
        document.title = 'MS Agro Exim | Certificates';
        window.scrollTo(0, 0); // Scroll to the top of the page
      }, []);

    const opts = {
        fullScreen: {
          autoStart: true, // Auto-start in full-screen mode
        },
        // Other options...
      };
  return (
    <>
      <Navbar />
    <section className="banner" style={{backgroundImage:`url(${banner})`}}>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="title-area-data">
                    <h2 className='text-white'>Certificates and Awards</h2>
                    {/* <p className='text-white'>Wholesome Wonders: A Gastronomic Adventure with Agricultural Treasures</p> */}
                    </div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/"><i className="fa-solid fa-house"></i> Home</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">About Us</li>
                    </ol>
                </div>
                <div className="col-lg-5">
                    <div className="row">
                    <div className="col-6">
                        <div className="title-area-img">
                            <img alt="title-area-img" src={over_img1}/>
                            {/* <img alt="pata" className="pata" src="assets/img/pata.png" /> */}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="title-area-img two">
                            <img alt="title-area-img" src={over_img2} />
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div className="container gap">
        <div className="heading-two">
            <h2>Certificates and Awards</h2>
            <div className="line"></div>
        </div>
            <div className="row">
                <Fancybox options={opts}>
                    <div className="col-lg-4 col-sm-6 text-center">
                        <h6 className='my-4'>In Export Of Groundnut Inshell 1st place </h6>
                        <div className="photo-gallery">
                            <div className="featured-imagebox featured-imagebox-gallery">
                            <a data-fancybox="gallery" href={certificates1} >
                                <div className="featured-link">
                                    <div className="featured-thumbnail">                                                
                                        <img className="img-fluid" src={certificates1} alt="certificates-img" />
                                    </div>
                                    <div className="featured-overlay"></div>
                                </div>
                            </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 text-center">
                        <h6 className='my-4'>Rajasthan State Export Award</h6>

                        <div className="photo-gallery">
                            <div className="featured-imagebox featured-imagebox-gallery">
                            <a data-fancybox="gallery" href={certificates2}>
                                <div className="featured-link">
                                    <div className="featured-thumbnail">                                                
                                        <img className="img-fluid" src={certificates2} alt="certificates-img" />
                                    </div>
                                    <div className="featured-overlay"></div>
                                </div>
                            </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 text-center">
                        <h6 className='my-4'>Two Star Export house</h6>
                        <div className="photo-gallery">
                            <div className="featured-imagebox featured-imagebox-gallery">
                            <a data-fancybox="gallery" href={certificates3}>
                                <div className="featured-link">
                                    <div className="featured-thumbnail">                                                
                                        <img className="img-fluid" src={certificates3} alt="certificates-img" />
                                        </div>
                                    <div className="featured-overlay"></div>
                                </div>
                            </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 text-center">
                        <h6 className='my-4'>Maruti Udhyog Star Export House</h6>
                        <div className="photo-gallery">
                            <div className="featured-imagebox featured-imagebox-gallery">
                            <a data-fancybox="gallery" href={certificates4}>
                                <div className="featured-link">
                                    <div className="featured-thumbnail">                                                
                                        <img className="img-fluid" src={certificates4} alt="certificates-img" />
                                        </div>
                                    <div className="featured-overlay"></div>
                                </div>
                            </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 text-center">
                        <h6 className='my-4'>Balaji Udhyog Star Export House</h6>
                        <div className="photo-gallery">
                            <div className="featured-imagebox featured-imagebox-gallery">
                            <a data-fancybox="gallery" href={certificates5}>
                                <div className="featured-link">
                                    <div className="featured-thumbnail">                                                
                                        <img className="img-fluid" src={certificates5} alt="certificates-img" />
                                        </div>
                                    <div className="featured-overlay"></div>
                                </div>
                            </a>
                            </div>
                        </div>
                    </div>
                </Fancybox>
            </div>
    </div>
    <Footer />
    </>
  )
}

export default Certificates;
