import React, { useEffect} from 'react'
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import banner from '../assets/img/company_banner.jpg';
import over_img1 from '../assets/img/companies_over_img1.jpg';
import over_img2 from '../assets/img/companies_over_img2.jpg';
import company1 from '../assets/img/company1.jpg';
import company2 from '../assets/img/company2.jpg';
// import company3 from '../assets/img/company3.jpg';
import company4 from '../assets/img/company4.jpg';
import company5 from '../assets/img/company5.jpg';

function Companies() {
    useEffect(() => {
        document.title = 'MS Agro Exim | Our Companies';
        window.scrollTo(0, 0); // Scroll to the top of the page
      }, []);
       const logodataOptions = {
            loop: true,
            dots: false,
            nav: false,
            autoplay: true,
            autoplayTimeout: 3000,
            responsive: {
                0: {
                items: 1,
                },
                480: {
                items: 1,
                },
                800: {
                items: 2,
                },
                1000: {
                items: 3,
                },
                1200: {
                items: 4,
                },
                1400: {
                items: 5,
                },
            },
        };
  return (
    <>
      <Navbar />
    <section className="banner" style={{backgroundImage:`url(${banner})`}}>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="title-area-data">
                    <h2 className='text-white'>Our Companies</h2>
                    {/* <p className='text-white'>Wholesome Wonders: A Gastronomic Adventure with Agricultural Treasures</p> */}
                    </div>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/"><i className="fa-solid fa-house"></i> Home</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Our Companies</li>
                    </ol>
                </div>
                <div className="col-lg-5">
                    <div className="row">
                    <div className="col-6">
                        <div className="title-area-img">
                            <img alt="title-area-img" src={over_img1}/>
                            {/* <img alt="pata" className="pata" src="assets/img/pata.png" /> */}
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="title-area-img two">
                            <img alt="title-area-img" src={over_img2} />
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="gap">
   <div className="container">
      <div className="review">
              <h3>Our Companies</h3>
              <div className="single-comment">
                    {/* <img alt="img" src="https://via.placeholder.com/160x160"/> */}
                    <img alt="img" src={company1}/>
                    <div className="ps-md-4">
                      <div className="d-flex align-items-center">
                        <h4>Maruti Udhyog</h4>   
                        {/* <span>Jun 07, 2023</span> */}
                        </div>
                        <p className='my-3'>Welcome to Maruti Udhyog, a trailblazer in the agro-industry, dedicated to fostering innovation, sustainability, and prosperity in agriculture. Nestled in the heart of agro-excellence, Maruti Udhyog is a name synonymous with cutting-edge practices, quality produce, and a steadfast commitment to nurturing both the land and the livelihoods it supports.</p>
                        <span className='my-3'>Our Agrarian Vision:</span>
                        <p className='my-3'>At Maruti Udhyog, our vision extends beyond fields of green to envision a future where agriculture seamlessly integrates modern innovation with traditional wisdom. We aspire to be at the forefront of agro-excellence, driving positive change and contributing to the well-being of farmers and consumers alike.</p>
                    </div>
                    <ul className="star">
                     <li><i className="fa-solid fa-star"></i></li>
                     <li><i className="fa-solid fa-star"></i></li>
                     <li><i className="fa-solid fa-star"></i></li>
                     <li><i className="fa-solid fa-star"></i></li>
                     <li><i className="fa-solid fa-star"></i></li>
                  </ul>
              </div>
              <div className="single-comment">
                <img alt="img" src={company2}/>
                <div className="ps-md-4">
                  <div className="d-flex align-items-center">
                    <h4>Balaji Udyog</h4>
                    {/* <span>Jun 07, 2023</span> */}
                    </div>
                    <p className='my-3'>Welcome to Balaji Udyog, a pioneering name in the agro-industry dedicated to delivering excellence in quality and sustainability. With a steadfast commitment to agriculture and a passion for nourishing communities, Balaji Udyog has emerged as a trusted leader in the production and distribution of premium agro products.</p>
                    <span className='my-3'>Our Roots:</span>
                    <p className='my-3'>Established with a vision to enhance the agricultural landscape, Balaji Udyog has its roots firmly grounded in the rich soil of tradition and innovation. Founded by a team of dedicated professionals, our journey began with a profound belief in the potential of agro products to elevate lives and foster agricultural prosperity.</p>
                </div> 
                <ul className="star">
                  <li><i className="fa-solid fa-star"></i></li>
                  <li><i className="fa-solid fa-star"></i></li>
                  <li><i className="fa-solid fa-star"></i></li>
                  <li><i className="fa-solid fa-star"></i></li>
                  <li><i className="fa-solid fa-star"></i></li>
                </ul>
              </div>
              <div className="single-comment">
                <img alt="img" src={company4}/>
                <div className="ps-md-4">
                  <div className="d-flex align-items-center">
                    <h4>Balaji Agrotech</h4>
                    {/* <span>Jun 07, 2023</span> */}
                    </div>
                    <p className='my-3'>Welcome to Balaji Agrotech, a name synonymous with innovation, quality, and a deep-rooted commitment to transforming the agricultural landscape. As a leading player in the agro-products domain, Balaji Agrotech stands at the forefront of sustainable farming, delivering premium-quality products that nourish both the land and those who cultivate it.</p>
                    <span className='my-3'>Our Vision:</span>
                    <p className='my-3'>At Balaji Agrotech, we envision a future where agriculture thrives in harmony with nature, where farmers reap the benefits of cutting-edge practices, and where consumers enjoy the bounty of nutritious, responsibly cultivated agro-products. Our vision is to be a catalyst for positive change in the agro-industry.</p>
                </div> 
                <ul className="star">
                  <li><i className="fa-solid fa-star"></i></li>
                  <li><i className="fa-solid fa-star"></i></li>
                  <li><i className="fa-solid fa-star"></i></li>
                  <li><i className="fa-solid fa-star"></i></li>
                  <li><i className="fa-solid fa-star"></i></li>
                </ul>
              </div>
              <div className="single-comment">
                <img alt="img" src={company5}/>
                <div className="ps-md-4">
                  <div className="d-flex align-items-center">
                    <h4>Murli Flour Mills Pvt. Ltd.</h4>
                    {/* <span>Jun 07, 2023</span> */}
                    </div>
                    <p className='my-3'>Murli Flour Mills Private Limited is a Non-govt company, incorporated on 08 Aug, 1996. It's a private unlisted company and is classified as'company limited by shares'.</p>
                    <p className='my-3'>MURLI FLOUR MILLS Pvt Ltd. is a leading manufacturer and exporter fenugreek, seaseme seed, cumin, fennel, groundnut seed, bajra & other agricultural products. We are engaged in processing, supplying & exporting a wide range of Indian Spices. We ensure premium quality merchandise. We guarantee the most competitive prices We assure prompt delivery.</p>
                </div> 
                <ul className="star">
                  <li><i className="fa-solid fa-star"></i></li>
                  <li><i className="fa-solid fa-star"></i></li>
                  <li><i className="fa-solid fa-star"></i></li>
                  <li><i className="fa-solid fa-star"></i></li>
                  <li><i className="fa-solid fa-star"></i></li>
                </ul>
              </div>
      </div>
   </div>
</section>
    {/* <div className="gap">
      <div className="container">
          <div className="trusted-sponsor">
            <h3>Our Companies</h3>
            <OwlCarousel className="logodata owl-carousel owl-theme" {...logodataOptions}>
              <div className="partner item">
                <img alt="sponsor-img" src={company1} />
              </div>
              <div className="partner item">
                <img alt="sponsor-img" src={company2} />
              </div>
              <div className="partner item">
                <img alt="sponsor-img" src={company4} />
              </div>
              <div className="partner item">
                <img alt="sponsor-img" src={company5} />
              </div>
            </OwlCarousel>
          </div>
      </div>
    </div> */}
    <Footer />

    </>
  )
}

export default Companies;
